import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormNewsletter = lazy(
  () => import("./form-newsletter-G0ECIV2M.js").then((module) => ({
    default: module.FormNewsletter
  }))
);
function FormNewsletter({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormNewsletter, { ...props });
}
const useFormNewsletterContent = () => {
  return useMemo(
    () => ({
      formNewsletter: {
        component: FormNewsletter
      }
    }),
    []
  );
};
export {
  LazyFormNewsletter as FormNewsletter,
  useFormNewsletterContent
};
